import tw from "twin.macro"
import React, { useState } from "react"
import { css } from '@emotion/core'

import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Modal from "../components/Modal"
import Button from "../components/Button"

const Crown = tw.small`
  text-base block text-white font-serif italic 
`

const H1 = tw.h1`
  text-5xl text-white text-center font-serif leading-none mb-3
  sm:(mb-0)
`

const P = tw.h1`
  text-white text-center font-serif
`

const Info = tw.span`
  border-r-0 block p-0 mb-3 leading-3
  sm:(border-r-2 px-2 inline-block)
`

const Index = ({data}) => {
  const { hero } = data

  const [registryModalOpen, setRegistryModalOpen] = useState(false)

  const toggleRegistryModalOpen = () => setRegistryModalOpen(!registryModalOpen)

  return (
    <Layout>
      <SEO />
      <Modal open={registryModalOpen} toggle={toggleRegistryModalOpen} />
      <section
        css={[
          css`
            background:
              linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),
              url(${hero.childImageSharp.fluid.src})
          `,
          tw`h-screen w-screen bg-cover bg-right p-8`,
        ]}
      >
        <div
          css={tw`w-full h-full flex items-center justify-center border-white border-8`}
        >
          <div tw='px-4'>
            <H1>
              <Crown>Peyton + Michael</Crown>
              The Paniks
            </H1>
            <P tw='mb-5'>
              <Info>12.06.20</Info>
              <Info>Vulcan Park</Info>
              <Info tw='border-none'>Birmingham, AL</Info>
            </P>
            <div tw='text-center'>
              <Button tw='sm:mr-2 text-white' onClick={toggleRegistryModalOpen}>Registry</Button>
              <Button href="https://g.page/visitvulcan?share" target="_blank" tw="text-white">Directions</Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
query {
  hero: file(relativePath: {eq: "hero.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
}
`

export default Index