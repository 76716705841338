import React from 'react'
import tw from 'twin.macro'
import { css } from '@emotion/core'
import Button from './Button'

const Modal = ({ open, toggle }) => {
    const registries = [
        { label: 'Pottery Barn', url: 'https://www.potterybarn.com/registry/nlmhvxmsj5/registry-list.html' },
        { label: 'Williams Sonoma', url: 'https://www.williams-sonoma.com/registry/nlmhvxmsj5/registry-list.html' },
        { label: 'Target', url: 'http://tgt.gifts/thepaniks' },
    ]

    return (
        <div
            css={[
                tw`bg-gray-700 w-full h-full fixed flex pointer-events-none opacity-0 bg-opacity-75 items-center justify-center transition ease-in-out duration-200`,
                open && tw`opacity-100 pointer-events-auto`
            ]}
        >
            <div
                css={[
                    tw`p-4 bg-white font-serif text-gray-800 relative transform translate-y-4 transition ease-in-out duration-200`,
                    open && tw`opacity-100 translate-y-0`
                ]}
            >
                <button
                    onClick={toggle}
                    css={[
                        tw`absolute text-4xl text-white`,
                        'bottom: 100%; right: 0%;'
                    ]}
                >×</button>
                <div
                    tw="p-8 border-2 border-gray-600 text-center"
                >
                    <h2 tw="text-3xl font-serif leading-none mb-3">Registries</h2>
                    <p tw="mb-6">We are registered at the following locations:</p>
                    {registries.map(registry => {
                        return <Button href={registry.url} target="_blank" tw="mb-3 text-center md:(mb-0 mr-3) hover:(text-white bg-gray-700 border-gray-700)">{registry.label}</Button>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Modal